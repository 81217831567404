.price-item {
    display: flex;
    justify-content: space-between; /* Ensure name and price are separated */
    align-items: center;            /* Vertically center the items */
    width: 100%;                    /* Full width of parent */
  }
  
  .price-name {
    font-weight: bold;              /* Make the name bold */
  }
  
  .price-button {
    width: 40%;               /* Fixed width for the price button */
  }
  
  .price-text {
    text-decoration-line: underline;
    text-decoration-thickness: var(--underline-thickness);
  }