section {
    display: flex;
    /* Enable flexbox for layout */
    flex-direction: column;
    /* Stack children vertically */
    height: 100vh;
    /* Full viewport height */

    margin: 0;
}

.contactSection {
    flex: 1;
}

.contactSection>h1 {
    margin: 0;

    margin-top: var(--large-spacing);
}

.contactSection>h2 {
    margin-top: 0.5rem;
    margin-bottom: 0rem;
}