/* Apply global styles */
body {
    font-family: var(--font-family);
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-normal);
    color: var(--color-white);
    
    margin: 0;

    padding: var(--screen-padding);
    padding-bottom: 0px;

    background-color: var(--color-black);

    overflow-x: hidden;
}

/* Headings */
h1 {
    font-size: var(--font-size-big);
    font-weight: var(--font-weight-light);
    color: var(--color-white);
}

h2 {
    font-size: var(--font-size-subheading);
    font-weight: var(--font-weight-light);
    color: var(--color-white);
}
  