.content-tracker {
    content-visibility: hidden;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    right: var(--padding);
    transform: translateY(-50%);
    gap: 20px;
  }
  
  .row {
    display: flex;
    align-items: center;
  }
  
  .circle {
    width: 20px;
    height: 20px;
    background-color: var(--color-blue); 
    border-radius: 50%;
    margin-right: 10px;
  }

  .text {
    font-size: small;
  }

    /* Desktop overrides */
    @media (min-width: 768px) {
        .content-tracker {
            content-visibility: visible;
        }
      }
      