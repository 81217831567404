section {
    display: flex;
    /* Enable flexbox for layout */
    flex-direction: column;
    /* Stack children vertically */
    height: 100vh;
    /* Full viewport height */

    margin: 0;
}

.menuSection {
    flex: 1;
    margin-right: var(--screen-padding);

}

.arrow {
    position: absolute;
    left: 0;
    right: 0;
    margin-inline: auto;
    width: fit-content;
    
    bottom: 0px;
    /* Distance from the bottom */
    text-align: center;
    /* Center the arrow text */
    color: var(--color-white);

    transform: translateY(9%);
}


.downArrow {
    margin-top: var(--small-spacing);
    font-size: var(--font-size-arrow);
    font-weight: 100;
    transform: rotate(-45deg);
}

.downArrow::after {
    content: "\2199";
}

.arrowText {
    margin: 0px;
}


.menuSection>h1 {
    margin: 0;

    margin-top: var(--large-spacing);
}

.menuSection>h2 {
    margin-top: 0.5rem;
}