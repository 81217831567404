.sectionButtons {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: start;
    gap: 1rem;
    width: 70%;
}

.sectionButtons > button {
    transition: box-shadow 1s ease;
    cursor:pointer;
}

.sectionButtons > button:hover {
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.8);
}

.sectionButtons > * {
    /* Add padding to the buttons for better touch targets */
    width: 100%;
    font-size: var(--font-size-normal);

    color: var(--color-white);
    background-color: var(--color-blue);

    border: 0px;
    border-radius: var(--border-radius);

    padding: 1rem;
    padding-left: 0rem;
    padding-right: 0rem;

    max-width: 200px;

    text-align: center;
}

.sectionButtons > div {
    background-color: transparent;
}

.sectionButtons > div > p {
    padding: 0px;
    margin: 0px;
}

button > p {
    padding: 0px;
    margin: 0px;
    margin-top: 1px;
    margin-bottom: 1px;
}

  /* Desktop overrides */
  @media (min-width: 768px) {
    .sectionButtons {
        flex-direction: row;
        width: fit-content;
    }

    .sectionButtons > *  {
        /* Add padding to the buttons for better touch targets */
        min-width: 300px;
    }
  }
  