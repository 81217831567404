/* Global variables (Mobile first design) */
:root {
    /* Colors */
    --color-black: #000000;
    --color-white: #ffffff;
    --color-blue: #3498db;
  
    /* Font family */
    --font-family: 'Helvetica';
    --font-weight-light: 100;
  
    /* Mobile font sizes (using rem for scalability) */
    --font-size-big: 2.6rem;      /* Mobile: 48px (48 / 16 = 3rem) */
    --font-size-subheading: 1.35rem; /* Mobile: 24px */
    --font-size-normal: 1.05rem;    /* Mobile: 20px */

    --font-size-arrow: 2rem;

    /* Spacing (for padding/margin, etc.) */
    --screen-padding: 1.5rem;
    --padding: 1rem;
    --margin: 1.4rem;

    --new-section-spacing: 3.5rem;
    --large-spacing: 1rem;
    --small-spacing: 0.1rem;

    --border-radius: 12px; /* Mobile border radius */

    --underline-thickness: 1px;
  }
  
  /* Desktop overrides */
  @media (min-width: 768px) {
    :root {
      /* Font sizes for desktop */
      --font-size-big: 10vw;          /* Adjust as needed for desktop */
      --font-size-subheading: 3rem;
      --font-size-normal: 1.5rem;

      --font-size-arrow: 4rem;
      
      
    /* Spacing (for padding/margin, etc.) */
    --screen-padding: 5rem;
    --padding: 1rem;
    --margin: 1.4rem;

    --new-section-spacing: 3.5rem;
    --large-spacing: 1rem;
    --small-spacing: 0.1rem;

    --border-radius: 16px; /* Mobile border radius */

    --underline-thickness: 1px;
    }
  }
  