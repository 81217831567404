.priceList {
    margin-top: var(--new-section-spacing);

    height: 50vh;
}

.priceList > h2 {
    margin-bottom: 0rem;
}

.itemDescription {
    margin: 0px;
    padding: 0px;
}